import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: HS Wall Holds, Free Standing Holds, Walks`}</p>
    <p>{`then,`}</p>
    <p>{`Death by HSPU’s`}</p>
    <p>{`1-minute 1:00, 2-minute 2:00, etc.`}</p>
    <p>{`rest 1:00 then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`KBS’s (53/35)`}</p>
    <p>{`T2B`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      